import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import { jsPDF } from "jspdf";


function exportMe(div_id){
  const doc = new jsPDF();
  doc.text("Hello world!", 10, 10);
  doc.save("a4.pdf");
}

export {exportMe};