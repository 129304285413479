import $ from 'jquery';

window.jQuery = $;
window.$ = $;

require('datatables.net-bs5');

function download_csv(csv, filename) {
  var csvFile;
  var downloadLink;
  csvFile = new Blob([csv], {type: 'text/csv'});
  downloadLink = document.createElement('a');
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
}

function datatable_params(table_id) {
  let data_opts = $('#' + table_id).parents().find('.datatable_cst').attr('data-opts');
  let data = jQuery.parseJSON(data_opts);
  return data;
}

function generateCsv(table_id) {
  let filename = 'export';
  if (datatable_params(table_id).export_name != undefined) {
    filename = datatable_params(table_id).export_name;
  }
  filename = filename + '_' + new Date().toLocaleString();
  var csv = [];
  var table = document.getElementById(table_id);
  var rows = table.rows;
  for (var i = 0; i < rows.length; i++) {
    var row = [], cols = rows[i].querySelectorAll('td:not(.no-export), th:not(.no-export)');
    for (var j = 0; j < cols.length; j++)
      row.push(cols[j].innerText.replace(/,/g, ''));

    csv.push(row.join(','));
  }
  download_csv(csv.join('\n'), filename);
}


function get_filter_params(filter_group_id) {
  let filter = '#' + filter_group_id;
  let filter_params = '';
  $(filter).find('.filter').each(function () {
    if ($(this).prop('nodeName') == 'SELECT') {
      let name = $(this).children('option:selected').val().toLowerCase();
      if (!name.includes('select')) {
        filter_params += '&' + $(this).attr('name') + '=' + name;
      }
    }

    if ($(this).prop('nodeName') == 'INPUT' && $(this).attr('name') == 'date_filter') {
      var date = new Date($(this).val());
      if (date != 'Invalid Date') {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        filter_params += '&date_filter=' + [day, month, year].join('-');
      }
    }

    if ($(this).prop('nodeName') == 'INPUT' && $(this).attr('name') != undefined && $(this).attr('name').includes('_text_field_filter') && $(this).val() != '') {
      filter_params += '&' + $(this).attr('name') + '=' + $(this).val();
    }

    if ($(this).prop('nodeName') == 'INPUT' && $(this).attr('name') != undefined && $(this).attr('name') == 'daterange_calander_filter' && $(this).val() != '') {
      filter_params += '&' + $(this).attr('name') + '=' + $(this).val();
    }
  });
  return filter_params;
}


$(document).on('change', '.filter', function () {
  let filter_group_id = $(this).parent().attr('id');
  let filters_query = get_filter_params(filter_group_id);
  if (filters_query != '') {
    let datatabe_id = filter_group_id.split('_filters')[0];
    callDataTable(datatable_params(datatabe_id));
  }
});

function callDataTable(data) {
  $('#' + data.datatable_id).DataTable({
    'pagingType': 'first_last_numbers',
    'aLengthMenu': [data.aLengthMenu, data.aLengthMenu],
    'order': [data.default_sorting_order],
    'language': {
      search: '',
      "emptyTable": data.empty_table_message,
      searchPlaceholder: 'Search...', 'lengthMenu': '_MENU_',
    },
    'searching': data.data_table_searching,
    'paging':  data.paging,
    'bInfo': data.bInfo,
    'processing': true,
    'serverSide': true,
    'ajax': {
      'url': '/data_table?' + data.datatbale_params + get_filter_params(data.datatable_id + '_filters')
    },
    'columnDefs': data.styles,
    'columns': data.columns_keys,
    'bDestroy': true,
    'preDrawCallback': function () {
      $('#' + data.widget_body_id).find('.widget-loader-parent-div').removeClass('d-none');
    },
    'drawCallback': function (settings) {
      $('#' + data.widget_body_id).find('.widget-loader-parent-div').addClass('d-none');
      if (settings.json.data.length === 0) {
        $(`#${data.datatable_id}__noDataAvailble`).removeClass('d-none');
        $(`#${data.widget_body_id}`).removeClass('mh-200');
      } else {
        $(`#${data.datatable_id}__DataCenter`).removeClass('d-none');
      }
    },
  });

}

export {generateCsv, callDataTable};

$(document).ready(function () {
  $('.datatable_cst').each(function () {
    var data = jQuery.parseJSON($(this).attr('data-opts'));
    $(function () {
      callDataTable(data);
    });
  });
});
