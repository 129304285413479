// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "chartkick/chart.js"
import * as bootstrap from "bootstrap";

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import $ from 'jquery';

window.jQuery = $;
window.$ = $;
window.bootstrap = bootstrap;


require('datatables.net-bs5');
require('../custom_js/datatable');
require('../custom_js/exports');
require('../custom_js/richtext');
window.generateCsv = generateCsv;
window.callDataTable = callDataTable;
window.exportMe = exportMe;
import {generateCsv, callDataTable} from '../custom_js/datatable';
import {exportMe} from '../custom_js/exports';

require("trix");
require("@rails/actiontext");